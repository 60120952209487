import React from "react";
import {ApiLink} from "../link";
import {Callout} from "../alert";

// This section is the same for ODBC and OLEDB
const OutputParamNotes = ({api}) => (
    <>
        <p>
            SQL Server stored procedures can have integer return codes and output parameters.
            The return codes and output parameters are sent in the last packet from the server and are
            therefore not available to the application until all result sets from stored procedure (if any)
            are completely processed using {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '} method.
        </p>
        <Callout heading="Useful Article">
            There is a good article from Microsoft about
            dealing with SQL server return values and output parameters:
            {' '}
            <a className="alert-link" href="http://msdn.microsoft.com/en-us/library/ms971497.aspx">
                http://msdn.microsoft.com/en-us/library/ms971497.aspx
            </a>
        </Callout>
        <p>
            SQLAPI++ library automatically creates {' '}<ApiLink>SAParam</ApiLink>{' '} object to represent function
            return value.
            You can refer to this {' '}<ApiLink>SAParam</ApiLink>{' '} object using SQLAPI++ predefined
            name <code>"RETURN_VALUE"</code>.
        </p>
        <p>
            SQLAPI++ library may have difficulties with
            output {' '}<strong>[n]varchar(max)</strong>{' '} and {' '}<strong>varbinary(max)</strong>{' '} parameters
            because they are not supported by some specific {` ${api} `} API versions.
        </p>
    </>
);

export {
    OutputParamNotes
};
