import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles
} from "../../components/server-guide";
import {Callout} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {CodeRef} from "../../components/code";
import {ApiLink} from "../../components/link";
import {OutputParamNotes} from "../../components/sql-server/output-parameters";
import {SqlServerUserID} from "../../components/sql-server/sql-server-user-id";

import {options} from "../../data/sql-server-oledb";


const connectApiNotes = ({optionsLink}) => (
    <>
        <p>
            Starting from version 4.0.3 SQLAPI++ library uses ODBC as the default API when working with
            SQL Server. If you want to use OLE DB, you should set
            {' '}<code>"UseAPI"</code>{' '} <a href={optionsLink}>connection option</a>{' '} before specifying
            SQL Server client or connecting to database:
        </p>
        <CodeSnippet>{`
SAConnection con;
con.setOption(_TSA("UseAPI")) = _TSA("OLEDB");
        `.trim()}
        </CodeSnippet>
        <p>
            If the library cannot find OLEDB interface it throws an exception.
        </p>
        <Callout heading="Minimum Version">
            The SQLAPI++ library requires SQL Server OLE DB version 2.5 or higher.
        </Callout>
    </>
);

const sDBString = ({optionsLink}) => (
    <>
        <div>A connection string like</div>
        <CodeSnippet>
            {`[<server_name>@][<database_name>][;<driver_connection_option_list>]`}
        </CodeSnippet>

        <ul>
            <li>
                <CodeRef>{`<server_name>`}</CodeRef>{' '} - connects to a specified server. If it's omitted SQLAPI++
                tries to connect to default local server instance
            </li>
            <li>
                <CodeRef>{`<database_name>`}</CodeRef>{' '} - connects to a database with the specified name. If
                it's omitted SQLAPI++ tries to connect to default database
            </li>
            <li>
                <CodeRef>{`<driver_connection_parameters_list>`}</CodeRef>{' '} - SQL Server OLEDB driver specific
                parameters list
            </li>
        </ul>

        <p>
            To connect to a named instance of SQL Server
            use {' '}<CodeRef>{`<server name\\instance name>`}</CodeRef>{' '} instead
            of {' '}<CodeRef>{`<server_name>`}</CodeRef>{' '}
            (use double back slash in C++ constants, for
            example <CodeRef>{`"MyServer\\\\SQLEXPRESS@pubs"`}</CodeRef>).
        </p>

        <div>
            Since SQLNCLI (SQL Server 2005) also available protocol specific server name part of the connection string:
        </div>
        <ul>
            <li><CodeRef>{`lpc:<servername>[\\instancename]`}</CodeRef> - using shared memory</li>
            <li>
                <CodeRef>{`tcp:<servername>[\\<instancename>],<port>`}</CodeRef> or <CodeRef>{`tcp:<IPAddress>[\\<instancename>],<port>`}</CodeRef> -
                using TCP/IP
            </li>
            <li>
                <CodeRef>{`np:\\<computer_name>\\pipe\\<pipename>`}</CodeRef> or <CodeRef>{`np:\\<IPAddress>\\pipe\\<pipename>`}</CodeRef> -
                using named pipes
            </li>
            <li><CodeRef>{`via:<servername>[\\instancename],<nic_number>:<port>`}</CodeRef> - using VIA</li>
        </ul>

        <p>
            To open Compact Edition database file use {' '}<CodeRef>{`<file path>`}</CodeRef>{' '} connection string
            (see {' '}<a href={optionsLink}>connection option</a>{' '} also).
        </p>
        <Callout heading="Compact Edition Requirements">
            Only UNICODE version of the SQLAPI++ can be used with SQL Server Compact Edition.
        </Callout>
    </>
);

const ErrorNotes = () => (
    <p>
        In SQL Server (OLEDB) API {' '}<ApiLink>SAException::ErrPos</ApiLink>{' '} method returns the number of the line
        within SQL statement where the error occurred.
    </p>
);

const Page = () => (
    <ServerGuide server="SQL Server (OLEDB)"
                 connectApiNotes={connectApiNotes}
                 sDBString={sDBString}
                 sUserID={SqlServerUserID}
                 outputParamNotes={() => <OutputParamNotes api="OLEDB"/>}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="SQL Server (OLEDB)"
                                                                      nativeCommand="ICommand::Cancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="SQL Server (OLEDB)"
                                                                        cppHeader="ssOleDbAPI.h"
                                                                        apiClass="ssOleDbAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="SQL Server (OLEDB)"
                                                                                handleClass="ssOleDbConnectionHandles"
                                                                                cppHeader="ssOleDbAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="SQL Server (OLEDB)"
                                                                          handleClass="ssOleDbCommandHandles"
                                                                          cppHeader="ssOleDbAPI.h"/>}
                 errorNotes={ErrorNotes}
    />
);

export default Page;
