import React from "react";
import {ApiLink} from "../components/link";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'UseAPI',
        scope: 'api',
        description: `Forces SQLAPI++ library to use ODBC or OLE DB API.`,
        valid: <><code>"OLEDB"</code>, {' '}<code>"ODBC"</code></>,
        defaultValue: <><code>"ODBC"</code>. SQLAPI++ uses ODBC as the default API</>
    },
    {
        name: 'OLEDBProvider',
        scope: 'api',
        description: `Forces SQLAPI++ library to use specified OLEDB provider.`,
        valid: (
            <>
                empty, {' '}<code>"SQLNCLI"</code>,
                {' '}<code>"SQLOLEDB"</code>, {' '}<code>"Microsoft.SQLSERVER.MOBILE.OLEDB.3.0"</code>, ...
            </>),
        defaultValue:
            <>
                empty. SQLAPI++ tries to initialize {' '}<code>"MSOLEDBSQL19"</code>{' '} first and then (if it fails)
                {' '}<code>"MSOLEDBSQL"</code>{' '}, {' '}<code>"SQLNCLI11"</code>, {' '}<code>"SQLNCLI10"</code>,
                {' '}<code>"SQLNCLI"</code>, {' '}<code>"SQLOLEDB"</code>
            </>,
        special:
            <>
                {' '}<code>"CompactEdition"</code>, {' '}<code>"CompactEdition.3.0"</code>,
                {' '}<code>"CompactEdition.3.5"</code>, {' '}<code>"CompactEdition.4.0"</code>
                <p>
                    Requires UNICODE SQLAPI++ build.
                </p>
                <p>
                    Can be used with SQLServer Compact Edition. SQLAPI++ uses
                    "Microsoft.SQLSERVER.CE.OLEDB.4.0", "Microsoft.SQLSERVER.CE.OLEDB.3.5"
                    or "Microsoft.SQLSERVER.MOBILE.OLEDB.3.0" OLEDB provider, sets the
                    {' '}<code>"Execute_riid"</code>{' '} = {' '}<code>"IID_IRowset"</code>{' '} option
                    for related {' '}<ApiLink>SACommand</ApiLink>{' '} instances, uses
                    string buffer for numeric data types.
                    The following CE options also available if this option value is used:
                </p>
                <pre>{`
DBPROP_SSCE_ENCRYPTDATABASE
DBPROP_SSCE_TEMPFILE_DIRECTORY
DBPROP_SSCE_TEMPFILE_MAX_SIZE
DBPROP_SSCE_DEFAULT_LOCK_ESCALATION
DBPROP_SSCE_AUTO_SHRINK_THRESHOLD
DBPROP_SSCE_MAX_DATABASE_SIZE
DBPROP_SSCE_FLUSH_INTERVAL
DBPROP_SSCE_DEFAULT_LOCK_TIMEOUT
DBPROP_SSCE_ENCRYPTIONMODE
DBPROP_SSCE_MAXBUFFERSIZE
DBPROP_SSCE_DBCASESENSITIVE
                `.trim()}
                </pre>
                <p>
                    See SQL Server CE documentation for details. DBPROP_SSCE_DBPASSWORD property
                    is set when the password is provide in the
                    {' '}<ApiLink>SAConnection::Connect</ApiLink> method.
                </p>
            </>
    },
    {
        name: 'CoInitializeEx_COINIT',
        scope: 'api',
        description: `Specifies the COM library initialization mode. See SQLOLEDB documentation for more information.`,
        valid:
            <>
                {' '}<code>"COINIT_MULTITHREADED"</code>, {' '}<code>"COINIT_APARTMENTTHREADED"</code>,
                {' '}<code>"Skip"</code>{' '} (SQLAPI++ doesn't initialize COM),
                {' '}<code>"Default"</code>{' '} (SQLAPI++ tries to set "COINIT_MULTITHREADED" value; if it fails,
                SQLAPI++ tries to set "COINIT_APARTMENTTHREADED")
            </>,
        defaultValue: <code>"Default"</code>
    },
    {
        name: 'CreateDatabase',
        scope: 'connection',
        description:
            <>
                Applies if {' '}<CodeBlend>"OLEDBProvider"</CodeBlend>{' '}={' '}<CodeBlend>"CompactEdition"</CodeBlend>.
                Forces SQLAPI++ library to create SQLServer
                Compact/Mobile database before connection is established.
            </>,
        valid: <><code>"VARIANT_TRUE"</code>, {' '}<code>"VARIANT_FALSE"</code></>,
        defaultValue: <code>"VARIANT_FALSE"</code>
    },
    {
        name: 'SSPROP_INIT_AUTOTRANSLATE',
        scope: 'connection',
        description: `This option configures OEM/ANSI character translation. See SQLOLEDB documentation for more information.`,
        valid: <><code>"VARIANT_TRUE"</code>, {' '}<code>"VARIANT_FALSE"</code></>,
        defaultValue: <>see SQLOLEDB documentation</>
    },
    {
        name: 'SSPROP_INIT_ENCRYPT',
        scope: 'connection',
        description: `This option configures the data going over the network encryption. See SQLOLEDB 
                      documentation for more information.`,
        valid:
            <>
                    {' '}<code>"VARIANT_TRUE"</code>,
                    {' '}<code>"VARIANT_FALSE"</code>,
                    for MSOLEDBSQL19 and above the valid values are
                    {' '}<code>"no", "yes", "true", "false", "Optional", "Mandatory", "Strict"</code>
            </>,
        defaultValue: <>see SQLOLEDB documentation</>
    },
    {
        name: 'SSPROP_INIT_TRUST_SERVER_CERTIFICATE',
        scope: 'connection',
        description: `This option configures whether the server certificate will be trusted or validated.`,
        valid:
            <ul>
                <li><code>"VARIANT_TRUE"</code>{' '} - the server certificate is trusted</li>
                <li><code>"VARIANT_FALSE"</code> - the server certificate is not trusted and will be validated</li>
            </ul>,
        defaultValue: <>see SQLOLEDB documentation</>
    },
    {
        name: 'SSPROP_INIT_FILENAME',
        scope: 'connection',
        description: `Specifies the primary file name of an attachable database. See SQLOLEDB documentation for more information.`,
        valid: <>String containing the file path.</>
    },
    {
        name: 'SSPROP_INIT_PACKETSIZET',
        scope: 'connection',
        description: `This option configures packet size. See SQLOLEDB documentation for more information.`,
        valid: <>Number as a string</>,
        defaultValue: <>see SQLOLEDB documentation</>
    },
    {
        name: 'SSPROP_INIT_MARSCONNECTION',
        scope: 'connection',
        description: `Forces SQLAPI++ to initiate MARS connection. See SQLOLEDB documentation for more information.`,
        valid: <><code>"VARIANT_TRUE"</code>, {' '}<code>"VARIANT_FALSE"</code></>,
        defaultValue: <>see SQLOLEDB documentation</>
    },
    {
        name: 'SSPROP_INIT_FAILOVERPARTNER',
        scope: 'connection',
        description: `Allows to set the connection mirror partner. See SQLOLEDB documentation for more information.`,
        valid: <>Failover partner name</>
    },
    {
        name: 'SSPROP_INIT_MULTISUBNETFAILOVER',
        scope: 'connection',
        description: `Configures OLE DB Driver for SQL Server to provide faster detection of and connection to the (currently) active server.`,
        valid: <><code>"VARIANT_TRUE"</code>, {' '}<code>"VARIANT_FALSE"</code></>,
        defaultValue: <>see MSOLEDBSQL documentation</>
    },
    {
        name: 'SSPROP_INIT_APPNAME, APPNAME',
        scope: 'connection',
        description: `Specifies the client application name. See SQLOLEDB documentation for more information.`,
        valid: <>client application name string</>,
        defaultValue: <>none</>
    },
    {
        name: 'SSPROP_INIT_WSID, WSID',
        scope: 'connection',
        description: `Specifies the client workstation name. See SQLOLEDB documentation for more information.`,
        valid: <>client workstation name string</>,
        defaultValue: <>none</>
    },
    {
        name: 'SSPROP_AUTH_MODE',
        scope: 'connection',
        description: `Specifies the SQL or Active Directory authentication used. See MSOLEDBSQL documentation for more information.`,
        valid: <><code>"SqlPassword"</code> and other valid authentication mode string</>,
        defaultValue: <>none</>
    },
    {
        name: 'SSPROP_AUTH_ACCESS_TOKEN',
        scope: 'connection',
        description: `Specifies the access token used to authenticate to Azure Active Directory. See MSOLEDBSQL documentation for more information.`,
        valid: <>The string like <code>"eyJ0eXAiOi..."</code> - in the format extracted from an OAuth JSON response</>,
        defaultValue: <>none</>
    },
    {
        name: 'DBPROP_INIT_TIMEOUT',
        scope: 'connection',
        description: `Sets the connection time out.`,
        valid:
            <>
                String containing number of seconds before a connection times out.
                A value of {' '}<code>"0"</code>{' '} indicates
                an infinite time-out.
            </>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change this option and uses the value set by SQLOLEDB. See
                SQLOLEDB documentation for details.
            </>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseDynamicCursor',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ to use scrollable dynamic command handle. Sets
                {' '}<CodeBlend>DBPROP_OTHERINSERT</CodeBlend>{' '} = {' '}<CodeBlend>VARIANT_TRUE</CodeBlend>,
                {' '}<CodeBlend>DBPROP_OTHERUPDATEDELETE</CodeBlend> = {' '}<CodeBlend>VARIANT_TRUE</CodeBlend>,
                {' '}<CodeBlend>DBPROP_CANSCROLLBACKWARDS</CodeBlend> = {' '}<CodeBlend>VARIANT_TRUE</CodeBlend>.
            </>,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'ICommandPrepare',
        scope: 'command',
        description: `Controls current command preparation with ICommandPrepare interface.`,
        valid:
            <ul>
                <li><code>"skip"</code>{' '} - skips {' '}<CodeBlend>ICommandPrepare::Prepare()</CodeBlend> call</li>
                <li>
                    <code>"required"</code>{' '} - calls {' '}<CodeBlend>ICommandPrepare::Prepare()</CodeBlend>{''}
                    and reports errors if any
                </li>
                <li>
                    <code>"optional"</code>{' '} - calls {' '}<CodeBlend>ICommandPrepare::Prepare()</CodeBlend>{' '}
                    and ignores errors if any
                </li>
                <li>
                    <code>"SetParameterInfo"</code>{' '} - calls
                    {' '}<CodeBlend>ICommandWithParameters::SetParameterInfo()</CodeBlend>{' '} before
                    {' '}<CodeBlend>ICommandPrepare::Prepare()</CodeBlend>, fixes SQLOLEDB bug KB235053.
                    {' '}<ApiLink>SAParam</ApiLink>{' '} scale and precision should be defined for numeric parameters
                    before
                    statement prepared.
                </li>
            </ul>,
        defaultValue: <code>"skip"</code>
    },
    {
        name: 'UseStreamForLongOrLobParameters',
        scope: 'parameter',
        description:
            <>
                Sets the IID_SequentalStream interface is used for the related parameter for the writing the long/LOB data to the server.
                Requires the OLEDB driver supports {' '}<code>[N]VARACHAR(MAX) / VARBINART(MAX)</code> data types.
                For using with {' '}<code>[N]TEXT / IMAGE</code> data types it
                also requires the option {' '}<code>"ICommandPrepare"</code>{' '} set to {' '}<code>"SetParameterInfo"</code>{ ' '}.
            </>,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'Execute_riid',
        scope: 'command',
        description:
            <>
                Sets the requested interface for the rowset returned by the command. See SQLOLEDB documentation
                (<CodeBlend>ICommand::Execute()</CodeBlend> function) for details.
            </>,
        valid:
            <>
                {' '}<code>"IID_NULL"</code>{' '} (no rowset is returned),
                {' '}<code>"IID_IRowset"</code>{' '} (should be used with Compact Edition),
                {' '}<code>"IID_IStream"</code>,
                {' '}<code>"IID_ISequentialStream"</code>,
                {' '}<code>"IID_IMultipleResults"</code>
            </>,
        defaultValue: <><code>"IID_IMultipleResults"</code>{' '} (to create multiple results)</>
    },
    {
        name: 'DBPROP_COMMANDTIMEOUT',
        scope: 'command',
        description: `Sets the command time out.`,
        valid:
            <>
                String containing number of seconds before a command times out. A value of
                {' '}<code>"0"</code>{' '} indicates an infinite time-out.
            </>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change this option and uses the value set by SQLOLEDB. See
                SQLOLEDB documentation for details.
            </>
    },
    {
        name: 'DBPROP_COMMITPRESERVE',
        scope: 'command',
        description: `Determines the behavior of a rowset after a commit operation.`,
        valid: <><code>"VARIANT_TRUE"</code>, {' '}<code>"VARIANT_FALSE"</code></>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change this option and uses the value set by SQLOLEDB. See
                SQLOLEDB documentation for details.
            </>
    },
    {
        name: `DBPROP_SERVERCURSOR, DBPROP_OTHERINSERT, DBPROP_OTHERUPDATEDELETE, 
               DBPROP_OWNINSERT, DBPROP_OWNUPDATEDELETE, DBPROP_REMOVEDELETED, DBPROP_CANSCROLLBACKWARDS`,
        scope: 'command',
        description:
            <>
                <p>
                    Forces SQL Server to return result sets using one of the following methods:
                </p>
                <ul>
                    <li>
                        Default result sets, which:
                        <ul>
                            <li>provide maximal performance in fetching data</li>
                            <li>support only one active statement at a time on a connection</li>
                        </ul>
                    </li>
                    <li>
                        Server cursors, which:
                        <ul>
                            <li>support multiple active statements on a single connection</li>
                            <li>can decrease performance relative to a default result set</li>
                        </ul>
                    </li>
                </ul>
                <p>
                    You can request different cursor behaviors in a rowset by setting rowset properties
                    {' '}<CodeBlend>DBPROP_SERVERCURSOR, DBPROP_OTHERINSERT, DBPROP_OTHERUPDATEDELETE, DBPROP_OWNINSERT,
                    DBPROP_OWNUPDATEDELETE, DBPROP_REMOVEDELETED</CodeBlend>. Some properties can be safely combined
                    with others. See SQLOLEDB documentation to get more about how they affect SQL Server cursors.
                </p>
            </>,
        valid:
            <>
                <code>"VARIANT_TRUE"</code>, {' '}<code>"VARIANT_FALSE"</code>.
                For DBPROP_SERVERCURSOR value {' '}<code>"VARIANT_TRUE"</code>{' '}
                means SQLOLEDB implements the rowset using a server cursor,
                {' '}<code>"VARIANT_FALSE"</code>{' '} - SQLOLEDB implements
                the rowset using a default result set.
            </>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change this option and uses the value set by SQLOLEDB. See
                SQLOLEDB documentation for details
            </>
    }
];

export {
    options
};
