import React from "react";
import {CodeRef} from "../code";

// User ID notes are the same for ODBC and OLEDB
const SqlServerUserID = () => (
    <>
        <p>A string containing a user name to use when establishing the connection.</p>

        <p>
            If {' '}<CodeRef>sUserID</CodeRef>{' '} parameter is empty, SQLAPI++ library requests a secure, or trusted,
            connection to SQL Server.
        </p>
    </>
);


export {
    SqlServerUserID
};
